<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">

            <userMenu type="3" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title">账户中心</h1>
                </div>

                <div class="shop_form mt30">
                    <el-form ref="form" :model="form" :rules="rules" label-width="84px" size="small">
                        <el-form-item label="手机号：" class="mb10">
                            <span class="accountText">{{phone}}</span>

                            <a href="javascript:void(0)" class="accountLink" @click="$router.push('/shop/phone')">更改</a>
                        </el-form-item>

                        <el-form-item label="昵称：" class="mb10">
                            <span class="accountText">{{nickname}}</span>
                        </el-form-item>

                        <el-form-item label="密码：" class="mb10">
                            <span class="accountText">******</span>
                            <a href="javascript:void(0)" class="accountLink" @click="$router.push('/shop/password')">修改密码</a>
                        </el-form-item>

                        <el-form-item label="支付密码：" class="mb10" v-if="isPayPassword">
                            <span class="accountText">******</span>
                            <a href="javascript:void(0)" class="accountLink" @click="$router.push('/shop/accountPay')">修改支付密码</a>
                        </el-form-item>

                        <el-form-item class="mt24">
                            <el-button type="danger" :loading="submitLoading" @click="toExit">注销用户</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getUserInfo, postExit, getIsPayPassword} from '../../../api/public';
  const userMenu = () => import('../../../components/layout/shop/userMenu');
  export default {
    name: "shopAccount",
    data () {
      return {
        form: {
          phone: '',
          code: ''
        },
        rules: {
          phone: [
            { required: true, message: '请填写11位数的手机号码', trigger: 'blur' },
            { pattern: '^1[3456789]\\d{9}$', message: '请输入正确的手机号码', trigger: 'blur'}
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
        },
        isRequestSend: false,
        submitLoading: false,
        isSendCode: false,
        smsBtnText: '',
        isPayPassword: false,
        nickname: '',
        phone: ''
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getUserInfo()
      this.toGetIsPayPassword()
    },
    methods: {
      getUserInfo () {
        getUserInfo().then(res => {
          if (res.code === 0) {
            this.nickname = res.data.nickname
            if (res.data.phone.length > 10) {
              this.phone = res.data.phone.substr(0, 4) + '****' + res.data.phone.substr(7, 4)
            } else {
              this.phone = res.data.phone
            }
          }
        })
      },
      toExit () {
        this.$confirm('用户注销后将不可用，是否确认?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          postExit().then(res => {
            if (res.code === 0) {
              localStorage.removeItem('access_token_shop');
              this.$store.commit('changeLoginType', false);
              this.$router.replace('/login');
              this.$message({
                message: '您的申请将于1个工作日内完成，请您耐心等待。',
                type: 'success',
                showClose: true,
              });
            }
          })
        }).catch(() => {});
      },
      toGetIsPayPassword () {
        this.isRequestSend = true;
        getIsPayPassword().then(res => {
          this.isRequestSend = false
          if (res.code === 0) {
            this.isPayPassword = res.data
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      }
    },
    components: {
      userMenu
    }
  }
</script>
